import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import moment from 'moment/moment';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import LoginPage from '@/pages/Login/LoginPage';

function Home() {
  const { isLoading, isAuthenticated, error, user, logout } = useAuth0();
  const [redirectUrl, setRedirectUrl] = useState(window.location.pathname);

  useEffect(() => {
    const url = localStorage.getItem('redirectUrl') || 'safety-prediction';
    setRedirectUrl(url);
  }, []);

  const sessionStart = localStorage.getItem('sessionStart');
  if (sessionStart) {
    const timestamp = moment(sessionStart, 'YYYY-MM-DDTHH:mm:ssZ');
    if (moment().diff(timestamp, 'hours') > 6) {
      localStorage.removeItem('sessionStart');
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }
  if (isLoading) {
    return (
      <>
        <LoadingIndicator message={'Authentication in progress'} />
      </>
    );
  }

  if (error) {
    return <>Oops... An error occured during authentication, error message: {error.message}</>;
  }

  if (isAuthenticated) {
    if (user) {
      localStorage.setItem('sessionStart', user.updated_at as string);
    }
    const forwardTo = redirectUrl === undefined || redirectUrl === null ? '/safety-prediction' : redirectUrl;

    return <Navigate to={forwardTo} replace />;
  }

  return <LoginPage />;
}

export default Home;
