import { ReactElement, createContext } from 'react';
import { AllowedOrganisation } from '@/api/ui/requests';

export interface SmartMooringContextInterface {
  setSelectedOrganisation: (organisationId: string) => Promise<void>;
  getAvailableOrganisations: () => Promise<Array<AllowedOrganisation>>;
  selectedOrganisationId: string;
  selectedOrganisationName: string;
  pageTitle: string;
  setPageTitle: (title: string) => Promise<void>;
  pageTitleIcon: ReactElement | undefined;
  setPageTitleIcon: (element: ReactElement | undefined) => Promise<void>;
}
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <SmartMooringProvider>.');
};

export const initialContext: SmartMooringContextInterface = {
  setSelectedOrganisation: stub,
  getAvailableOrganisations: stub,
  selectedOrganisationId: '',
  selectedOrganisationName: '',
  pageTitle: document.title,
  setPageTitle: stub,
  pageTitleIcon: undefined,
  setPageTitleIcon: stub,
};

const SmartMooringContext = createContext<SmartMooringContextInterface>(initialContext);

export default SmartMooringContext;
