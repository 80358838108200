import { Suspense, lazy, useEffect, useMemo } from 'react';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import { Alert, Box, Grid, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DirectionsBoatIcon from '@material-design-icons/svg/filled/directions_boat_filled.svg';
import moment from 'moment';
import { useSafetyPredictionServiceGetMooredVessels } from '@/api/ui/queries';
import { MooredVessel, SafetyPredictionType } from '@/api/ui/requests';
import { ReactComponent as MarkerSvg } from '@/assets/svg/marker.svg';
import { ReactComponent as OperationalView } from '@/assets/svg/operational.svg';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import NoScrollBox from '@/components/layout/NoScrollBox/NoScrollBox';
import { MapMarkerProps } from '@/components/maps/Map/types';
import useOrganisation from '@/hooks/useOrganisation';
import usePageTitle from '@/hooks/usePageTitle';
import { Analytics } from '@/services/analytics';
import classes from './index.module.css';

const StyledMarkerSvg = styled(MarkerSvg)(({ theme }) => ({
  'circle:nth-of-type(1)': { fill: theme.palette.text },
  height: '12px',
}));

const BelowLowerLimitIcon = styled(DirectionsBoat)(({ theme }) => ({
  fill: theme.palette.below_lower_limit.main,
  height: '20px',
}));

const DisabledIcon = styled(DirectionsBoat)(({ theme }) => ({
  fill: theme.palette.disabled.main,
  height: '20px',
}));

const AboveUpperLimitIcon = styled(DirectionsBoat)(({ theme }) => ({
  fill: theme.palette.above_upper_limit.main,
  height: '20px',
}));

const AboveLowerLimitIcon = styled(DirectionsBoat)(({ theme }) => ({
  fill: theme.palette.above_lower_limit.main,
  height: '20px',
}));

const vesselThresholdIcon = (mooredVessel: MooredVessel) => {
  let icon = <BelowLowerLimitIcon />;
  if (!mooredVessel.safetyPrediction) {
    icon = <DisabledIcon />;
  } else if (mooredVessel.safetyPrediction.type === SafetyPredictionType.ABOVE_LOWER_LIMIT) {
    icon = <AboveLowerLimitIcon />;
  } else if (mooredVessel.safetyPrediction.type === SafetyPredictionType.ABOVE_UPPER_LIMIT) {
    icon = <AboveUpperLimitIcon />;
  } else if (mooredVessel.safetyPrediction.type === SafetyPredictionType.BELOW_LOWER_LIMIT) {
    icon = <BelowLowerLimitIcon />;
  }

  return icon;
};

function popup(mooredVessel: MooredVessel) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        {vesselThresholdIcon(mooredVessel)}
        &nbsp;<Typography variant={'bodySemiBoldMedium'}>{mooredVessel.vessel.name}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingLeft: '36px' }}>
        <Typography variant={'bodySemiBoldSmall'}>
          {mooredVessel.vessel.vesselType} - {mooredVessel.vessel.vesselClass}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'bodyRegularSmall'}>
          <StyledMarkerSvg />
          &nbsp;{mooredVessel.berthName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'bodyRegularSmall'}>Arrived at: {moment(mooredVessel.arrivalTime).format('DD-MM-YY HH:mm')}</Typography>
      </Grid>
      {mooredVessel.safetyPrediction && (
        <Grid item xs={12}>
          <Typography variant={'bodySemiBoldSmall'} component={'span'}>
            Safety assessed at:&nbsp;
            {moment(mooredVessel.safetyPrediction.forecast.lastUpdate).format('DD-MM-YY HH:mm')}
            <br />
          </Typography>

          <Typography variant={'bodySemiBoldSmall'} component={'span'}>
            MBL:&nbsp;{mooredVessel.safetyPrediction.percentage}%{' '}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

const OperationalMap = lazy(() => import('@/components/maps/Map/BerthMap'));

const markerClass = (vessel: MooredVessel) => {
  let markerClass = classes.marker;
  if (!vessel.dmaCaseIdentifier) markerClass = classes.inactiveMarker;
  else {
    if (vessel.safetyPrediction && vessel.safetyPrediction.type === SafetyPredictionType.BELOW_LOWER_LIMIT) {
      markerClass = classes.belowLowerMarker;
    } else if (vessel.safetyPrediction && vessel.safetyPrediction.type === SafetyPredictionType.ABOVE_LOWER_LIMIT) {
      markerClass = classes.exceededLowerMarker;
    } else if (vessel.safetyPrediction && vessel.safetyPrediction.type === SafetyPredictionType.ABOVE_UPPER_LIMIT) {
      markerClass = classes.exceededUpperMarker;
    }
  }
  return markerClass;
};

function SafetyPredictionOperationalView() {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  usePageTitle('Operational - Map', <OperationalView width={32} key={'Operational - Map'} />);
  const navigate = useNavigate();
  const { selectedOrganisationId } = useOrganisation();
  const {
    isError,
    error,
    dataUpdatedAt,
    isLoading,
    data: vessels,
  } = useSafetyPredictionServiceGetMooredVessels({ xSelectedOrganisationId: selectedOrganisationId });

  useEffect(() => {
    Analytics.track('Operational - Map', {});
  }, []);

  const markers: MapMarkerProps[] = useMemo(() => {
    if (!vessels) return [];
    return vessels.map((vessel) => {
      return {
        latitude: vessel.mooredPosition.latitude,
        longitude: vessel.mooredPosition.longitude,
        markerIcon: {
          icon: DirectionsBoatIcon,
          className: markerClass(vessel),
        },
        popup: {
          content: popup(vessel),
          closeButton: false,
        },
        onMouseOver: (event) => {
          event.target.openPopup();
        },
        onMouseOut: (event) => {
          event.target.closePopup();
        },
        onClick: () => {
          if (vessel.dmaCaseIdentifier) {
            navigate(`/safety-prediction/operational/berths/${vessel.berthId}/vessels/${vessel.vessel.mmsi}?dmaCaseId=${vessel.dmaCaseIdentifier}`);
          }
        },
      };
    });
  }, [vessels, navigate]);

  if (isError) {
    return <ApiErrorAlert error={error} />;
  }

  if (!isLoading && markers.length === 0) {
    return (
      <Box sx={{ marginX: 'auto', marginY: 10, width: '50vw' }}>
        <Alert severity="warning" color={'warning'}>
          No moored vessels found (yet).
        </Alert>
      </Box>
    );
  }
  if (vessels) {
    return (
      <NoScrollBox component="div">
        <Suspense fallback={<LoadingIndicator message={'Generating data to display vessels on the map. Please be patient...'} />}>
          <OperationalMap mapMarkers={markers} showZoomControl={true} clustering={true} />
          <div>{moment(dataUpdatedAt).format('DD-MM-YYYY HH:mm:ss')}</div>
        </Suspense>
      </NoScrollBox>
    );
  }

  return <LoadingIndicator message={'Loading map with detected vessels...'} />;
}

export default SafetyPredictionOperationalView;
