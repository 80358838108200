import { AccountCircle, HomeRounded } from '@mui/icons-material';
import Logout from '@mui/icons-material/Logout';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from '@/assets/images/notfound.png';
import SmartMooringLogo from '@/assets/images/smartmooring-logo.png';
import TwinnLogo from '@/assets/images/twinn-logo.png';
import useLicence from '@/hooks/useLicence';

function PermissionDenied() {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { roles } = useLicence();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
      <Grid container direction={'row'}>
        <Grid item xs={12}>
          <Box marginBottom={6} marginLeft={10}>
            <Grid container direction={'row'} alignItems={'center'}>
              <Grid item xs={9}>
                <img src={SmartMooringLogo} width={300} alt={'Smart Mooring logo'} title={'Smart Mooring logo'} />
              </Grid>
              <Grid item xs={3}>
                <img src={TwinnLogo} width={200} alt={'Twinn logo'} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={5} pl={10}>
          <Stack direction={'column'}>
            <Box>
              <h1>Permission denied</h1>
              <Typography variant={'bodyRegularMedium'} component={'p'}>
                The requested page is not accessible you do not have enough access rights. Your current role(s) are: {roles.join(', ')}.
              </Typography>
            </Box>
            <Box marginTop={4}>
              <Button
                startIcon={<HomeRounded />}
                color={'primary'}
                variant={'contained'}
                title={'Logout'}
                onClick={() => navigate('/safety-prediction')}
                sx={{ marginRight: 2 }}
              >
                Home
              </Button>
              <Button startIcon={<Logout />} color={'primary'} variant={'contained'} title={'Logout'} onClick={() => logout()}>
                Logout
              </Button>
              <Button
                startIcon={<AccountCircle />}
                sx={{ marginLeft: 6 }}
                color={'primary'}
                variant={'contained'}
                title={'Smart Society account portal'}
                href={'https://account.smart-society.io/account-settings'}
              >
                Smart Society
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={7}>
          <img src={NotFoundImage} alt={'Not enough permissions'} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PermissionDenied;
