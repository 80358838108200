import { ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appSettings } from '@/services/config';

interface Auth0ProviderSmartMooringProps {
  children: ReactNode;
}

export const authNamespace = 'https://non-oidc.claims/';

export const NAV_NO_LICENSE = '/no-license';
export const NAV_MISSING_FEATURE = '/missing-feature';
export const NAV_PERMISSION_DENIED = '/permission-denied';

export function isErrorPage(path: string): boolean {
  return path.startsWith(NAV_NO_LICENSE) || path.startsWith(NAV_PERMISSION_DENIED) || path.startsWith(NAV_MISSING_FEATURE);
}

const Auth0ProviderSmartMooring = ({ children }: Auth0ProviderSmartMooringProps) => {
  const domain = appSettings.authentication.domain;
  const clientId = appSettings.authentication.clientId;
  const audience = appSettings.authentication.audience;
  const issuer = appSettings.authentication.issuer;
  const navigate = useNavigate();
  useLocation();

  const onRedirectCallback = (): void => {
    const redirectUrl = localStorage.getItem('redirectUrl') || window.location.pathname;
    localStorage.removeItem('redirectUrl');
    if (!isErrorPage(redirectUrl)) navigate(redirectUrl, { replace: true, state: { redirectTo: redirectUrl } });
    else navigate('/', { replace: true, state: { redirectTo: '/' } });
  };

  function getRedirectUrl(): string {
    if (typeof window !== 'undefined' && window.location.origin[window.location.origin.length - 1] === '/') {
      return window.location.origin;
    }
    return window.location.origin + '/';
  }

  if (!clientId || !audience) {
    throw new Error('Smart Society configuration missing');
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: getRedirectUrl(),
        audience: audience,
        // Auth0 v2 doesn't include profile and email scope by default
        scope: `profile email ${appSettings.authentication.scopes}`,
      }}
      issuer={issuer}
      onRedirectCallback={() => onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderSmartMooring;
