import { ChangeEvent, useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { useProfileServiceGetUser, useProfileServiceUpdateUserPreferences } from '@/api/ui/queries';

function UserPreferences() {
  const { data: userProfile } = useProfileServiceGetUser();
  const mutatieUserPreferences = useProfileServiceUpdateUserPreferences();
  const [receiveDailyReport, setReceiveDailyReport] = useState(userProfile?.userPreferences.sendDailySafetyPredictionEmail);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReceiveDailyReport(event.target.checked);
    mutatieUserPreferences.mutate({
      requestBody: {
        ...userProfile?.userPreferences,
        sendDailySafetyPredictionEmail: event.target.checked,
      },
    });
  };

  useEffect(() => {
    setReceiveDailyReport(userProfile?.userPreferences.sendDailySafetyPredictionEmail);
  }, [userProfile?.userPreferences.sendDailySafetyPredictionEmail]);

  if (!userProfile) return null;

  return (
    <>
      <h3>User preferences</h3>
      <Paper title={'User preferences'} sx={{ width: '100%', minWidth: '250px', maxWidth: '50vw' }} elevation={0}>
        <FormGroup>
          <FormControlLabel control={<Switch checked={receiveDailyReport} onChange={handleChange} />} label="Receive daily safety report by email" />
        </FormGroup>
      </Paper>
    </>
  );
}

export default UserPreferences;
