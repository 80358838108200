import { useEffect, useState } from 'react';
import { List, ListItem } from '@mui/material';
import { AllowedOrganisation } from '@/api/ui/requests';
import Select from '@/components/common/Select/Select';
import { localStorageOrganisationIdKey, localStorageOrganisationNameKey, localStorageRedirectUrlKey } from '@/features/Common/SmartMooringProvider';
import useOrganisation from '@/hooks/useOrganisation';

function AppMenuOrganisationSwitch() {
  const { selectedOrganisationId, setSelectedOrganisation, getAvailableOrganisations } = useOrganisation();

  const [allowedOrganisations, setAllowedOrganisations] = useState<Array<AllowedOrganisation>>([]);

  function switchOrganisation(organisationId: string) {
    setSelectedOrganisation(organisationId).then(() => localStorage.removeItem(localStorageRedirectUrlKey));
  }

  useEffect(() => {
    const getOrganisations = async () => {
      const organisations = await getAvailableOrganisations();
      setAllowedOrganisations(organisations);
      if (selectedOrganisationId && allowedOrganisations.length > 0) {
        const organisation = organisations.find((org) => org.organisationId === selectedOrganisationId);
        if (!organisation) {
          localStorage.removeItem(localStorageOrganisationNameKey);
          localStorage.removeItem(localStorageOrganisationIdKey);
          localStorage.removeItem(localStorageRedirectUrlKey);
          await setSelectedOrganisation(organisations[0].organisationId);
        }
      }
    };
    getOrganisations();
  }, [allowedOrganisations.length, getAvailableOrganisations, selectedOrganisationId, setSelectedOrganisation]);

  return allowedOrganisations.length >= 2 ? (
    <List>
      <ListItem>
        <Select
          name="organisationId"
          label="Organisation"
          options={allowedOrganisations}
          value={selectedOrganisationId}
          displayFunction={(value) => value?.organisationName ?? ''}
          valueFunction={(value) => value?.organisationId ?? ''}
          onChange={switchOrganisation}
          required={true}
          fullWidth={true}
          placeHolder="Select an option"
        ></Select>
      </ListItem>
    </List>
  ) : (
    <></>
  );
}

export default AppMenuOrganisationSwitch;
