import { MouseEvent } from 'react';
import { AccountCircleRounded as AccountCircleIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import useOrganisation from '@/hooks/useOrganisation';
import css from '@/parts/UserMenu/index.module.css';

interface UserMenuProps {
  menuOpenHandler: (e: MouseEvent<HTMLElement>) => void;
}

function UserMenuHandle({ menuOpenHandler }: UserMenuProps) {
  const { user } = useAuth0();
  const { organisationName, selectedOrganisationName } = useOrganisation();

  return (
    <>
      <div className={css.userMenu}>
        <div className={css.userInfo}>
          <span className={css.userName} title={user ? user.name : 'Unknown user'}>
            {user ? user.name : ''}
          </span>
          <span className={css.userCompany}>{selectedOrganisationName ? selectedOrganisationName : organisationName}</span>
        </div>
        <div className={css.userMenuIcon}>
          <Tooltip title="Open settings" arrow={true}>
            <IconButton className={css.userMenuIconButton} onClick={menuOpenHandler} sx={{ p: 0 }}>
              <AccountCircleIcon sx={{ color: 'black' }} className={css.userMenuIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </>
  );
}

export default UserMenuHandle;
