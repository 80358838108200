import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useProfileServiceGetOrganisation } from '@/api/ui/queries';
import { authNamespace } from '@/features/Authentication/Auth0ProviderSmartMooring';
import { CustomClaims } from '@/features/Authentication/Auth0Types';
import SmartMooringContext from '@/features/Common/SmartMooringContext';
import { appSettings } from '@/services/config';

function useLicence() {
  const { selectedOrganisationId } = useContext(SmartMooringContext);
  const { user } = useAuth0();
  const userProfile: CustomClaims = user?.[authNamespace];
  const defaultOrganisationId = userProfile?.organization_id;
  const defaultLicenseAgreements = userProfile?.license_agreements;
  const shouldUseDefaultOrganisation = () => selectedOrganisationId == defaultOrganisationId;

  const { data: selectedOrganisation } = useProfileServiceGetOrganisation(
    { xSelectedOrganisationId: selectedOrganisationId },
    [selectedOrganisationId],
    { enabled: !shouldUseDefaultOrganisation() }
  );

  const extractProductLicense = () => {
    const licenseAgreement = defaultLicenseAgreements?.find((license) => license.organization_id === defaultOrganisationId);
    const product = licenseAgreement?.products?.find((x) => x.name === appSettings.license.productName);
    return {
      licenseExpiryDate: licenseAgreement?.end_date,
      defaultRoles: product?.roles?.map((x) => x.name) ?? [],
      defaultFeatures: product?.features?.map((p) => p.name),
      active: !!product,
    };
  };

  const { licenseExpiryDate, defaultRoles: roles, defaultFeatures, active } = extractProductLicense();
  const features = (shouldUseDefaultOrganisation() ? defaultFeatures : selectedOrganisation?.features) ?? [];

  return {
    active,
    roles,
    features,
    hasFeature: (feature?: string) => !feature || features.indexOf(feature) !== -1,
    hasRole: (requestedRoles?: string[]) => !requestedRoles || (requestedRoles && requestedRoles.some((role) => roles.includes(role))),
    licenseExpiryDate,
  };
}

export default useLicence;
