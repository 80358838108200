import DirectionsBoatFilledRounded from '@mui/icons-material/DirectionsBoatFilledRounded';
import History from '@mui/icons-material/History';
import Timeline from '@mui/icons-material/Timeline';
import { ReactComponent as OperationalView } from '@/assets/svg/operational.svg';
import { ReactComponent as SimulationView } from '@/assets/svg/simulation.svg';
import { MenuItemGroupProps } from '@/components/common/Menu/types';
import { LicenseFeatures } from '@/types';

export const defaultMenuGroups: MenuItemGroupProps[] = [
  {
    title: 'Safety assesment',
    showTitle: true,
    showDividerAfter: true,
    icon: <Timeline />,
    items: [
      {
        displayText: 'Operational',
        description: 'Safety overview displaying a list of potential unsafe berths, moored vessels etc.',
        icon: <OperationalView width={32} />,
        linkTo: '/safety-prediction/operational',
        licenceFeature: LicenseFeatures.AIS_DETECTION,
        featureFlag: 'OPERATIONAL_VIEW',
      },
      {
        displayText: 'Simulation',
        description: 'Safety prediction overview of the port using a map of the port displaying the status of all berths',
        icon: <SimulationView width={32} />,
        linkTo: '/safety-prediction/simulation',
      },
      {
        displayText: 'Vessel clearance',
        description: 'Static safety analysis based on industry standards',
        icon: <DirectionsBoatFilledRounded sx={{ fontSize: 32 }} width={32} />,
        linkTo: '/vessel-clearance',
      },
      {
        displayText: 'Assessment history',
        description: 'View history of assessments',
        disabled: false,
        icon: <History sx={{ fontSize: 32 }} />,
        linkTo: '/safety-prediction/historical',
        // licenceFeature: LicenseFeatures.HISTORIC_OVERVIEW,
        featureFlag: 'HISTORICAL_VIEW',
      },
    ],
  },
];
