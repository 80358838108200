import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { SnackbarKey, closeSnackbar, useSnackbar } from 'notistack';
import { AllowedOrganisation, ProfileService } from '@/api/ui/requests';
import { appSettings } from '@/services/config';
import { authNamespace } from '../Authentication/Auth0ProviderSmartMooring';
import { CustomClaims } from '../Authentication/Auth0Types';
import SmartMooringContext, { SmartMooringContextInterface } from './SmartMooringContext';

export interface SmartMooringProviderOptions {
  children?: React.ReactNode;
  context?: React.Context<SmartMooringContextInterface>;
}

export const localStorageOrganisationIdKey = 'selectedOrganisationId';
export const localStorageOrganisationNameKey = 'selectedOrganisationName';
export const localStorageRedirectUrlKey = 'redirectUrl';

const SmartMooringProvider = (opts: SmartMooringProviderOptions): JSX.Element => {
  const { children, context = SmartMooringContext } = opts;
  const navigate = useNavigate();
  const { user: auth0User } = useAuth0();
  const auth0UserProfile: CustomClaims | undefined = auth0User?.[authNamespace];
  const auth0OrganisationId = auth0UserProfile?.organization_id;
  const auth0OrganisationName = auth0UserProfile?.organization_name;
  const license = auth0UserProfile?.license_agreements?.find((x) => x.products.find((x) => x.name == appSettings.license.productName));
  const licensedProduct = auth0UserProfile?.license_agreements?.flatMap((x) => x.products).find((x) => x.name == appSettings.license.productName);
  const snackbar = useSnackbar();
  const localStorageOrganisationId = localStorage.getItem(localStorageOrganisationIdKey);
  const localStorageOrganisationName = localStorage.getItem(localStorageOrganisationNameKey);

  const [selectedOrganisationId, setSelectedOrganisationId] = useState('');
  const [selectedOrganisationName, setSelectedOrganisationName] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [pageTitleIcon, setPageTitleIcon] = useState<ReactElement | undefined>(undefined);

  useEffect(() => {
    if (localStorageOrganisationId) {
      setSelectedOrganisationId(localStorageOrganisationId);
    } else if (auth0OrganisationId) {
      setSelectedOrganisationId(auth0OrganisationId);
    }
  }, [localStorageOrganisationId, auth0OrganisationId]);

  useEffect(() => {
    if (localStorageOrganisationName != undefined) {
      setSelectedOrganisationName(localStorageOrganisationName);
    } else if (auth0OrganisationName) {
      setSelectedOrganisationName(auth0OrganisationName);
    }
  }, [localStorageOrganisationName, auth0OrganisationName]);

  useEffect(() => {
    if (auth0UserProfile && !licensedProduct) {
      navigate(`/no-license`);
    } else if (license?.end_date) {
      const endDate = moment(license.end_date * 1000);
      const now = moment();

      const expireInDays = endDate.diff(now, 'days');
      if (expireInDays < 14) {
        const action = (licenseExpiration: SnackbarKey) => (
          <>
            <Button
              onClick={() => {
                closeSnackbar(licenseExpiration);
              }}
            >
              Dismiss
            </Button>
          </>
        );
        snackbar.enqueueSnackbar(`Your license will expire in ${expireInDays} days on ${endDate.format('DD-MM-YYYY')}`, {
          action,
          variant: 'warning',
          persist: true,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          style: { maxWidth: '85vw', marginTop: '60px' },
          key: 'licenseExpiration',
        });
      }
    }
  }, [auth0UserProfile, licensedProduct, license, navigate, snackbar]);

  // Check the users allowed organisations at login / render
  const getAvailableOrganisations = useCallback(async (): Promise<Array<AllowedOrganisation>> => {
    return ProfileService.getUser()
      .then((user) => user.allowedOrganisations)
      .catch(() => []);
  }, []);

  const setSelectedOrganisation = useCallback(
    async (organisationId: string): Promise<void> => {
      return getAvailableOrganisations().then((organisations) => {
        const organisation = organisationId ? organisations.find((x) => x.organisationId === organisationId) : undefined;
        if (organisation != undefined) {
          setSelectedOrganisationId(organisationId);
          if (organisation.organisationName) setSelectedOrganisationName(organisation.organisationName);
          localStorage.setItem(localStorageOrganisationIdKey, organisationId);
          localStorage.setItem(localStorageOrganisationNameKey, organisation.organisationName as string);
          // reload app, so it uses the right organisation.
          window.location.replace('/');
        } else {
          localStorage.removeItem(localStorageOrganisationIdKey);
          localStorage.removeItem(localStorageOrganisationNameKey);
          localStorage.removeItem(localStorageRedirectUrlKey);
        }
      });
    },
    [getAvailableOrganisations]
  );

  const setTitle = useCallback(async (title: string) => {
    return setPageTitle(title);
  }, []);

  const setPageIcon = useCallback(async (icon: ReactElement | undefined) => {
    return setPageTitleIcon(icon);
  }, []);

  const contextValue = useMemo(() => {
    return {
      getAvailableOrganisations,
      setSelectedOrganisation,
      selectedOrganisationId,
      selectedOrganisationName,
      pageTitle,
      setPageTitle: setTitle,
      pageTitleIcon,
      setPageTitleIcon: setPageIcon,
    };
  }, [
    getAvailableOrganisations,
    setSelectedOrganisation,
    selectedOrganisationId,
    selectedOrganisationName,
    pageTitle,
    setTitle,
    pageTitleIcon,
    setPageIcon,
  ]);

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default SmartMooringProvider;
