import { Box } from '@mui/material';

interface LoadingIndicatorProps {
  message: string | null;
}

function LoadingIndicator({ message }: LoadingIndicatorProps) {
  return (
    <Box display="flex" flexDirection={'column'} minWidth={'60vw'} className={'flex-container'}>
      <div className={'flex-child'}>
        <div className={'spinner'} />
        <span className={'loading-message'}>{message}</span>
      </div>
    </Box>
  );
}

export default LoadingIndicator;
