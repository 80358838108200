import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Berth } from '@/api/management/requests';
import { enqueueErrorSnackbar } from '@/components/management/EnqueueErrorSnackbar/EnqueueErrorSnackbar';

interface ToggleMooringDetectionForBerthDialogProps<TData, TError, TVariables, TContext> {
  open: boolean;
  berth: Berth | undefined;
  mutation: UseMutationResult<TData, TError, TVariables, TContext>;
  getMutationValues: () => TVariables;
  closeDialog: (disabled: boolean) => void;
}

function ToggleMooringDetectionForBerthDialog<TData, TError, TVariables, TContext>({
  open,
  berth,
  mutation,
  getMutationValues,
  closeDialog,
}: ToggleMooringDetectionForBerthDialogProps<TData, TError, TVariables, TContext>) {
  const snackbarContext = useSnackbar();

  const disableBerthMooringDetection = async () => {
    if (berth) {
      const values = getMutationValues();
      await mutation
        .mutateAsync(values)
        .then(() => closeDialog(true))
        .catch((error) => {
          closeDialog(false);

          enqueueErrorSnackbar(
            snackbarContext,
            `Could not ${berth?.mooringDetectionEnabled ? 'disable' : 'enable'} mooring detection for berth: ${error}`,
            'toggle-berth-mooring-detection-error'
          );
        });
    } else {
      closeDialog(false);
    }
  };
  return (
    <Dialog open={open} onClose={() => closeDialog(false)}>
      <DialogTitle>{berth?.mooringDetectionEnabled ? 'Disable ' : 'Enable '}mooring detection for berth?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to {berth?.mooringDetectionEnabled ? 'disable' : 'enable'} mooring detection for the following berth?
        </DialogContentText>
        <ul>
          <li>Berth Id: {berth?.berthId}</li>
          <li>Name: {berth?.name}</li>
          <li>Orientation: {berth?.orientation}</li>
        </ul>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => closeDialog(false)} data-testid="cancel-toggle-berth-mooring-detection-button">
            Cancel
          </Button>
          <LoadingButton
            loading={mutation.isLoading}
            variant="contained"
            color="error"
            loadingPosition="end"
            endIcon={berth?.mooringDetectionEnabled ? <DeleteIcon /> : <CheckCircleIcon />}
            onClick={disableBerthMooringDetection}
            disabled={!berth}
            data-testid="toggle-berth-mooring-detection-button"
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default ToggleMooringDetectionForBerthDialog;
