import { ReactElement, useContext, useEffect } from 'react';
import { useDocumentTitle } from 'usehooks-ts';
import SmartMooringContext from '@/features/Common/SmartMooringContext';
import { Analytics } from '@/services/analytics';

function usePageTitle(title: string, titleIcon?: ReactElement | undefined) {
  useDocumentTitle(title);

  const smartMooringContext = useContext(SmartMooringContext);

  useEffect(() => {
    if (title !== smartMooringContext.pageTitle) {
      smartMooringContext.setPageTitle(title);
      Analytics.pageview(title);
    }
  }, [smartMooringContext, title]);

  useEffect(() => {
    if (titleIcon && !titleIcon.key) {
      throw new SyntaxError("Missing key for prop: 'titleIcon'.");
    }

    if (titleIcon?.key !== smartMooringContext.pageTitleIcon?.key) {
      smartMooringContext.setPageTitleIcon(titleIcon);
    }
  }, [smartMooringContext, title, titleIcon]);
}

export default usePageTitle;
